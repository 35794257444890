.body {
    background-image: url("../images/login-svg.svg" );
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    width: 3000000;
    
  
}

