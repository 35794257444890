body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@keyframes call {
  15% {
    box-shadow: 0 0 0 1.5px rgba(156, 126, 28, 0.5);
  }
  25% {
    box-shadow: 0 0 0 3px rgba(156, 126, 28, 0.5),
      0 0 0 6px rgba(156, 126, 28, 0.3);
  }
  30% {
    box-shadow: 0 0 0 5px rgba(156, 126, 28, 0.5),
      0 0 0 10px rgba(156, 126, 28, 0.3);
  }
}

@keyframes pulse {
  0% {
    box-shadow: inset 0 0 5px rgba(128, 0, 128, 0.8),
      inset 0 0 10px rgba(128, 0, 128, 0.5),
      inset 0 0 15px rgba(128, 0, 128, 0.3);
  }
  50% {
    box-shadow: inset 0 0 2px rgba(128, 0, 128, 0.8),
      inset 0 0 5px rgba(128, 0, 128, 0.5), inset 0 0 8px rgba(128, 0, 128, 0.3);
  }
  100% {
    box-shadow: inset 0 0 0px rgba(128, 0, 128, 0.8),
      inset 0 0 2px rgba(128, 0, 128, 0.5), inset 0 0 5px rgba(128, 0, 128, 0.3);
  }
}

@keyframes spin {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.my-component {
  background: #fff;
}

.fullscreen-enabled .my-component {
  background: #000;
}
